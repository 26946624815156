import React, { useEffect, useRef, useState } from 'react';
import ConfettiFireworks from './ConfettiFireworks';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';

const EngagementTracker = () => {
  const lastVisibleTimestampRef = useRef(Date.now()); // Initialize with current time
  const lastInteractionTimestampRef = useRef(Date.now()); // Initialize with current time
  const eventCountRef = useRef(
    parseInt(localStorage.getItem('EngagementTrackerEventCount'), 10) || 0
  ); // Ref for event count
  const [confettiVisible24Hours, setConfettiVisible24Hours] = useState(false);
  const [confettiVisible7Days, setConfettiVisible7Days] = useState(false);
  const [confettiMessage24Hours, setConfettiMessage24Hours] = useState('');
  const [confettiMessage7Days, setConfettiMessage7Days] = useState('');
  const lastMinAlertDateRef = useRef(localStorage.getItem('lastMinAlertDate'));
  const lastTargetAlertDateRef = useRef(
    localStorage.getItem('lastTargetAlertDate')
  );
  const lastStreakAlertDateRef = useRef(
    localStorage.getItem('lastStreakAlertDate')
  );
  const [confettiDuration, setConfettiDuration] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        lastVisibleTimestampRef.current = -1; // Page becomes invisible
      } else {
        lastVisibleTimestampRef.current = Date.now(); // Page becomes visible
      }
    };

    const handleUserInteraction = () => {
      lastInteractionTimestampRef.current = Date.now();
    };

    const handleIframeInteraction = (iframe) => {
      if (!iframe.contentWindow || !iframe.contentDocument) return;

      const interactionHandler = () => {
        lastInteractionTimestampRef.current = Date.now();
      };

      const iframeWindow = iframe.contentWindow;
      const options = { capture: true, passive: true };
      iframeWindow.addEventListener('click', interactionHandler, options);
      iframeWindow.addEventListener('keydown', interactionHandler, options);
      iframeWindow.addEventListener('mousemove', interactionHandler, options);
      iframeWindow.addEventListener('touchstart', interactionHandler, options);
      iframeWindow.addEventListener('scroll', interactionHandler, options);

      return () => {
        iframeWindow.removeEventListener('click', interactionHandler, options);
        iframeWindow.removeEventListener(
          'keydown',
          interactionHandler,
          options
        );
        iframeWindow.removeEventListener(
          'mousemove',
          interactionHandler,
          options
        );
        iframeWindow.removeEventListener(
          'touchstart',
          interactionHandler,
          options
        );
        iframeWindow.removeEventListener('scroll', interactionHandler, options);
      };
    };

    const options = { capture: true, passive: true };

    document.addEventListener(
      'visibilitychange',
      handleVisibilityChange,
      options
    );
    window.addEventListener('click', handleUserInteraction, options);
    window.addEventListener('keydown', handleUserInteraction, options);
    window.addEventListener('mousemove', handleUserInteraction, options);
    window.addEventListener('touchstart', handleUserInteraction, options);
    window.addEventListener('scroll', handleUserInteraction, options);

    const iframes = document.querySelectorAll('iframe');
    const iframeListeners = Array.from(iframes).map(handleIframeInteraction);

    return () => {
      document.removeEventListener(
        'visibilitychange',
        handleVisibilityChange,
        options
      );
      window.removeEventListener('click', handleUserInteraction, options);
      window.removeEventListener('keydown', handleUserInteraction, options);
      window.removeEventListener('mousemove', handleUserInteraction, options);
      window.removeEventListener('touchstart', handleUserInteraction, options);
      window.removeEventListener('scroll', handleUserInteraction, options);
      iframeListeners.forEach((cleanup) => cleanup && cleanup());
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTimestamp = Date.now();

      const pageVisibleFor30Seconds =
        lastVisibleTimestampRef.current !== -1 &&
        currentTimestamp - lastVisibleTimestampRef.current <= 60000;

      const userInteractedRecently =
        lastInteractionTimestampRef.current !== -1 &&
        currentTimestamp - lastInteractionTimestampRef.current <= 180000;

      if (userInteractedRecently) {
        lastVisibleTimestampRef.current = currentTimestamp;
      }

      if (!pageVisibleFor30Seconds && !document.hidden)
        lastVisibleTimestampRef.current = currentTimestamp;

      if (pageVisibleFor30Seconds && userInteractedRecently) {
        logEvent(currentTimestamp);
      }
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const logEvent = (timestamp) => {
    let storedEvents =
      JSON.parse(localStorage.getItem('EngagementTracker')) || [];

    // Add new event
    storedEvents.push({ timestamp });
    eventCountRef.current += 1;

    // Save to localStorage
    localStorage.setItem('EngagementTracker', JSON.stringify(storedEvents));
    localStorage.setItem('EngagementTrackerEventCount', eventCountRef.current);

    // Compact events if more than 1000
    if (storedEvents.length > 1000) {
      const compactEvent = { count: 1000, timestamp };
      storedEvents = [compactEvent];
      localStorage.setItem('EngagementTracker', JSON.stringify(storedEvents));
    }

    handleAlertAndConfetti(eventCountRef.current);
  };

  const handleAlertAndConfetti = (eventCount) => {
    const currentTime = Date.now();
    const storedEvents =
      JSON.parse(localStorage.getItem('EngagementTracker')) || [];

    lastMinAlertDateRef.current = localStorage.getItem('lastMinAlertDate');
    lastTargetAlertDateRef.current = localStorage.getItem(
      'lastTargetAlertDate'
    );
    lastStreakAlertDateRef.current = localStorage.getItem(
      'lastStreakAlertDate'
    );

    const recentEvents24Hours = storedEvents.filter(
      (event) => currentTime - event.timestamp < 24 * 60 * 60 * 1000
    );
    if (recentEvents24Hours.length >= 60) {
      const today = new Date().toISOString().split('T')[0];
      if (lastMinAlertDateRef.current !== today) {
        setConfettiVisible24Hours(true);
        setConfettiMessage24Hours('Congratulations!');
        setConfettiDuration(true);
        setDialogMessage(
          'Jai Bhim! Congratulations for engaging with Babasaheb today!'
        );
        setDialogOpen(true);
        setTimeout(() => setConfettiDuration(false), 20000);
        lastMinAlertDateRef.current = today;
        localStorage.setItem('lastMinAlertDate', today);
      }
    }

    if (recentEvents24Hours.length >= 120) {
      const today = new Date().toISOString().split('T')[0];
      if (lastTargetAlertDateRef.current !== today) {
        setConfettiVisible24Hours(true);
        setConfettiMessage24Hours('Great Job!');
        setConfettiDuration(true);
        setDialogMessage(
          'Jai Bhim! Congratulations for deeply engaging with Babasaheb today!'
        );
        setDialogOpen(true);
        setTimeout(() => setConfettiDuration(false), 20000);
        lastTargetAlertDateRef.current = today;
        localStorage.setItem('lastTargetAlertDate', today);
      }
    }

    const last7Days = Array.from({ length: 7 }, (_, i) => {
      const dayStart = currentTime - (i + 1) * 24 * 60 * 60 * 1000;
      const dayEnd = currentTime - i * 24 * 60 * 60 * 1000;
      return storedEvents.filter(
        (event) => event.timestamp >= dayStart && event.timestamp < dayEnd
      ).length;
    });

    if (last7Days.every((day) => day >= 60)) {
      const today = new Date().toISOString().split('T')[0];
      if (lastStreakAlertDateRef.current !== today) {
        setConfettiVisible7Days(true);
        setConfettiMessage7Days('Jai Bhim!');
        setConfettiDuration(true);
        setDialogMessage(
          'Jai Bhim! Congratulations for deeply engaging with Babasaheb for several day!'
        );
        setDialogOpen(true);
        setTimeout(() => setConfettiDuration(false), 20000);
        lastStreakAlertDateRef.current = today;
        localStorage.setItem('lastStreakAlertDate', today);
      }
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      {confettiVisible24Hours && confettiDuration && (
        <ConfettiFireworks message={confettiMessage24Hours} />
      )}
      {confettiVisible7Days && confettiDuration && (
        <ConfettiFireworks
          message={confettiMessage7Days}
          showConfetti={false}
        />
      )}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle style={{ textAlign: 'center' }}>
          🎉 Congratulations! 🎉
        </DialogTitle>
        <div style={{ padding: '20px', textAlign: 'center' }}>
          <p>{new Date().toDateString()}</p>
          <p>
            <>{dialogMessage}</>
          </p>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseDialog}
          >
            Jai Bhim!
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default EngagementTracker;
