import React, { useRef, useEffect, useState } from 'react';

const ConfettiFireworks = ({
  message = 'Congratulations!',
  showConfetti = true,
  animateText = true,
}) => {
  const canvasRef = useRef(null);
  const animationRef = useRef(null);
  const [showMessage, setShowMessage] = useState(false);
  const [bouncingMessages, setBouncingMessages] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    // Common Colors for Fireworks and Confetti
    const colors = [
      '#ff0',
      '#0f0',
      '#00f',
      '#f0f',
      '#f00',
      '#0ff',
      '#ffa500',
      '#8a2be2',
    ];

    const fireworkParticles = [];

    // Firework Particle Creation with Trails
    const createFirework = () => ({
      x: Math.random() * canvas.width,
      y: canvas.height - 50,
      size: Math.random() * 3 + 2, // Small initial size
      dx: Math.random() * 2 - 1,
      dy: Math.random() * -5 - 3, // Move upward first
      color: colors[Math.floor(Math.random() * colors.length)],
      trail: [], // Trail particles array
      life: 150, // Duration for the firework particle
      isExploded: false, // Flag to track if the particle has exploded
      explosionParticles: [], // Store explosion particles
    });

    // Firework Explosion Phase
    const explodeFirework = (firework) => {
      const explosionCount = 100; // Number of particles in explosion
      for (let i = 0; i < explosionCount; i++) {
        firework.explosionParticles.push({
          x: firework.x,
          y: firework.y,
          dx: Math.random() * 6 - 3, // Random explosion spread
          dy: Math.random() * 6 - 3,
          color: firework.color,
          size: Math.random() * 3 + 1,
          life: 100,
        });
      }
      firework.isExploded = true;
    };

    // Update Firework Particles with Trails
    const updateFireworks = () => {
      fireworkParticles.forEach((firework, index) => {
        // Update position for firework
        firework.x += firework.dx;
        firework.y += firework.dy;

        // Simulate gravity
        firework.dy += 0.05;

        // Add trail behind the firework
        firework.trail.push({
          x: firework.x,
          y: firework.y,
          color: firework.color,
          size: firework.size,
        });
        if (firework.trail.length > 20) firework.trail.shift(); // Limit trail length

        // Check if firework reaches the peak height
        if (firework.y < canvas.height / 2 && !firework.isExploded) {
          explodeFirework(firework); // Trigger explosion
        }

        // Remove firework after it is finished
        if (firework.life <= 0 && firework.explosionParticles.length === 0) {
          fireworkParticles.splice(index, 1);
        }

        // Update explosion particles if explosion has occurred
        if (firework.isExploded) {
          firework.explosionParticles.forEach((particle, particleIndex) => {
            particle.x += particle.dx;
            particle.y += particle.dy;
            particle.life -= 1;

            if (particle.life <= 0) {
              firework.explosionParticles.splice(particleIndex, 1);
            }
          });
        }
      });
    };

    // Draw Firework Particles with Trails
    const drawFirework = (firework) => {
      // Draw trails first (older to newer)
      firework.trail.forEach((trailParticle, index) => {
        ctx.beginPath();
        ctx.arc(
          trailParticle.x,
          trailParticle.y,
          trailParticle.size,
          0,
          Math.PI * 2
        );
        ctx.fillStyle = trailParticle.color;
        ctx.fill();
      });

      // Draw the main firework particle
      if (firework.isExploded) {
        firework.explosionParticles.forEach((particle) => {
          ctx.beginPath();
          ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
          ctx.fillStyle = particle.color;
          ctx.fill();
        });
      } else {
        ctx.beginPath();
        ctx.arc(firework.x, firework.y, firework.size, 0, Math.PI * 2);
        ctx.fillStyle = firework.color;
        ctx.fill();
      }
    };

    // Fireworks Animation Loop
    const animateFireworks = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Create new fireworks every few frames
      if (fireworkParticles.length < 50) {
        fireworkParticles.push(createFirework());
      }

      fireworkParticles.forEach(drawFirework);
      updateFireworks();
      animationRef.current = requestAnimationFrame(animateFireworks);
    };

    // Confetti Animation (for fallback)
    const confettiParticles = [];
    const createParticle = () => ({
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height - canvas.height,
      size: Math.random() * 10 + 3,
      dx: Math.random() - 0.5,
      dy: Math.random() * 2 + 1,
      color: colors[Math.floor(Math.random() * colors.length)],
      shape: 'circle',
      rotation: Math.random() * 360,
    });

    // Particle Drawing and Animation (Confetti)
    const drawParticle = (particle) => {
      ctx.save();
      ctx.fillStyle = particle.color;
      ctx.translate(particle.x, particle.y);
      ctx.rotate((particle.rotation * Math.PI) / 180);

      ctx.beginPath();
      ctx.arc(0, 0, particle.size / 2, 0, Math.PI * 2);
      ctx.fill();

      ctx.restore();
    };

    const updateParticles = () => {
      confettiParticles.forEach((particle) => {
        particle.x += particle.dx;
        particle.y += particle.dy;
        particle.rotation += 2;

        if (particle.y > canvas.height) {
          Object.assign(particle, createParticle(), { y: -particle.size });
        }
      });
    };

    const animateConfetti = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      confettiParticles.forEach(drawParticle);
      updateParticles();
      animationRef.current = requestAnimationFrame(animateConfetti);
    };

    // Start the animation loop
    if (showConfetti) {
      for (let i = 0; i < 200; i++) {
        confettiParticles.push(createParticle());
      }
      animateConfetti();
    } else {
      animateFireworks();
    }

    // Show the zooming message after a brief delay
    setTimeout(() => setShowMessage(true), 3000);

    // Trigger bouncing messages after zoom animation ends
    setTimeout(() => {
      setShowMessage(false);
      setBouncingMessages(true);
    }, 9000); // Wait for zoom to finish (3 cycles of 2s each)

    return () => cancelAnimationFrame(animationRef.current);
  }, [showConfetti]);

  useEffect(() => {
    if (!bouncingMessages || !animateText) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const colors = [
      '#ff0',
      '#0f0',
      '#00f',
      '#f0f',
      '#f00',
      '#0ff',
      '#ffa500',
      '#8a2be2',
    ];
    const bouncingTexts = Array.from({ length: 5 }).map(() => ({
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      dx: Math.random() * 4 + 2,
      dy: Math.random() * 4 + 2,
      color: colors[Math.floor(Math.random() * colors.length)],
      lastColorChangeTime: Date.now(),
    }));

    const updateBouncingTexts = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      bouncingTexts.forEach((text) => {
        // Update position
        text.x += text.dx;
        text.y += text.dy;

        // Bounce off edges
        if (text.x <= 0 || text.x >= canvas.width) text.dx *= -1;
        if (text.y <= 0 || text.y >= canvas.height) text.dy *= -1;

        // Update color only once every 1000ms for a smoother effect
        const now = Date.now();
        if (now - text.lastColorChangeTime >= 1000) {
          text.color = colors[Math.floor(Math.random() * colors.length)];
          text.lastColorChangeTime = now;
        }

        // Draw text
        ctx.save();
        ctx.fillStyle = text.color;
        ctx.font = 'bold 24px Arial';
        ctx.fillText(message, text.x, text.y);
        ctx.restore();
      });

      animationRef.current = requestAnimationFrame(updateBouncingTexts);
    };

    updateBouncingTexts();

    return () => cancelAnimationFrame(animationRef.current);
  }, [bouncingMessages, animateText]);

  return (
    <>
      <canvas
        ref={canvasRef}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 9999,
          pointerEvents: 'none',
        }}
      ></canvas>
      {showMessage && animateText && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '2rem',
            fontWeight: 'bold',
            animation: 'zoomInOut 2s ease-in-out 3',
            zIndex: 10000,
            textShadow: '2px 2px 4px rgba(0,0,0,0.8)',
            color: '#fff',
          }}
        >
          {message}
        </div>
      )}
      <style>
        {`
          @keyframes zoomInOut {
            0%, 100% {
              transform: translate(-50%, -50%) scale(1);
            }
            50% {
              transform: translate(-50%, -50%) scale(5);
            }
          }
        `}
      </style>
    </>
  );
};

export default ConfettiFireworks;
