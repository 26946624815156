import React, { useState } from 'react';
import './ProfilePageStyles.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';

import LogoutIcon from '@mui/icons-material/Logout';
import NotificationToggle from './notificationToggle/NotificationToggle';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { userLogoutHandler } from '../../utilities/user-data/UserLogout';
import { auth } from '../../config/firebase';
import BetaTester from './BetaTester/BetaTester';

function ProfilePage() {
  const user = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutLoading, setLogoutLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const linkToCopy = `https://baws.in?referer_id=${user.username}`;

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(
        "Please join me to build a community of readers of Dr Ambedkar's Writings and Speeches at " +
          linkToCopy
      )
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 10000); // Reset success message after 10 seconds
      })
      .catch((error) => {
        console.error('Copy failed:', error);
      });
  };

  const handleLogout = async () => {
    setLogoutLoading(true);
    setTimeout(() => {
      userLogoutHandler({
        auth,
        dispatch,
      });
      setLogoutLoading(false);
      navigate('/');
    }, 1500);
  };

  return user.displayName ? (
    <div className="profile-container">
      {logoutLoading && (
        <div className="overlay-style-loader">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        </div>
      )}
      <div className="profileCard-container">
        <div className="profile-header">
          <Avatar
            className="avatar-pfp"
            alt="Remy Sharp"
            sx={{ width: 100, height: 100 }}
          >
            <span className="avatar-initial">
              {user.displayName
                .trim()
                .toUpperCase()
                .split(' ')
                .map((e) => e[0].toUpperCase())
                .join('')}
              {/* do with style */}
            </span>
          </Avatar>
          <h3>{user.displayName}</h3>
          <p>@{user.username}</p>
        </div>
        <div className="profile-actions">
          <Button
            onClick={handleLogout}
            variant="outlined"
            startIcon={<LogoutIcon />}
          >
            Logout
          </Button>
          <div className="toggle--switches">
            <NotificationToggle />
            <BetaTester />
            <div className="inviteYourFriend">
              {user.isLoggedIn && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <span
                  style={{ cursor: 'pointer', color: 'blue' }}
                  onClick={copyToClipboard}
                >
                  Click to Invite your friends !!!
                </span>
              )}
              {copySuccess && (
                <p style={{ color: 'green' }}>
                  Invitation copied to clipboard, please send to your friends!
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Navigate to={'/'} />
  );
}

export default ProfilePage;
