import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import parse from 'html-react-parser';
import './search.css';
import { getUserBookMarks } from '../utilities/user-data/UserData';
import { useSelector } from 'react-redux';
import { auth, db } from '../config/firebase';

import {
  getPageContext,
  initiate_interval_tree,
} from '../util/interval_tree.ts';
import { bookHistory, key_bookUrl } from '../util/constants';
import BookMarks from './BookMarks';

/**
 * Checks if a string is highly likely to be English.
 * @param {string} text - The input string to check.
 * @returns {boolean} - True if the string is likely English, otherwise false.
 */
function isLikelyEnglish(text) {
  if (!text || typeof text !== 'string') {
    return false; // Empty or non-string inputs are not English
  }

  // Define a regex to match common English characters (letters, numbers, and basic punctuation)
  const englishCharRegex = /^[a-zA-Z0-9 .,?!'"\-\(\)\[\]\/:;]+$/;

  // Count the number of matching English characters
  let englishCharCount = 0;
  let totalCharCount = text.length;

  for (let char of text) {
    if (char.match(englishCharRegex)) {
      englishCharCount++;
    }
  }

  // Determine the threshold (e.g., 80% English characters for "likely English")
  const englishCharRatio = englishCharCount / totalCharCount;

  return englishCharRatio > 0.8; // Adjust threshold as needed
}

export default function Search(props) {
  const [data, setData] = useState([]);
  const [userBookMarks, setUserBookMarks] = useState([]);
  const [userBookMarkDict, setUserBookMarkDict] = useState({});
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.userReducer);
  initiate_interval_tree();

  useEffect(() => {
    const search = async () => {
      var search_text = searchParams.get('q') || '';
      var stype = searchParams.get('searchType') || 'simple';
      let url = '';

      try {
        if (!search_text) return;

        if (search_text.length > 128) {
          alert('Try shorter text!');
          return;
        }

        if (!isLikelyEnglish(search_text)) {
          // call the translation API at https://asia-south1-woven-benefit-320216.cloudfunctions.net/mareng to translate the search_text to English.  The API is a simple wrapper around the Google Translate API.
          // It will return the translated text in the response of form {"detectedSourceLanguage":"en","input":"Hello World","translatedText":"Hello World"}.  The input is passed as a query parameter called message.
          // The response is then used to search the books.
          const response = await fetch(
            `https://asia-south1-woven-benefit-320216.cloudfunctions.net/mareng?message=${search_text}`
          );
          if (!response.ok) {
            alert(
              'We had trouble translating your search text.  Please try again.'
            );
            throw new Error('Network response was not ok');
          }
          const json = await response.json();
          search_text = json.translatedText;
          stype = 'vector';
        }

        if (stype === 'simple') {
          url = `https://lqpsx6t02d.execute-api.ap-south-1.amazonaws.com/default/baws-search?q=${search_text}`;
        } else if (stype === 'vector') {
          url = `https://0pe3xvyfpi.execute-api.ap-south-1.amazonaws.com/prod/search?page=1&size=25&vector_search=true&q=${search_text}`;
        } else {
          url = `https://0pe3xvyfpi.execute-api.ap-south-1.amazonaws.com/prod/search?page=1&size=25&filter_stop_words=true&q=${search_text}`;
        }

        const response = await fetch(url);

        // if user is logged in, get user's bookmarks
        if (user.isLoggedIn && auth?.currentUser?.uid) {
          const uid = auth?.currentUser?.uid;

          const [response, fetchUserBookmarks] = await Promise.all([
            fetch('url'),
            getUserBookMarks(uid),
          ]);

          setUserBookMarks(fetchUserBookmarks);
          setUserBookMarkDict(
            fetchUserBookmarks.reduce((acc, item) => {
              acc[item.url] = item;
              return acc;
            }, {})
          );
        }

        if (!response.ok) {
          alert('Result Not Found');
          throw new Error('Network response was not ok');
        }

        const json = await response.json();

        if (json && json.results.length > 0) {
          setData(json.results);
        } else {
          alert('Result Not Found');
        }
      } catch (error) {
        console.error(error);
      }
    };

    search();
  }, [searchParams.get('q'), searchParams.get('searchType')]);

  const setBookContextUrl = (e, volName, pageNum) => {
    e.preventDefault();

    bookHistory.set(key_bookUrl, {
      shortUrl: e.target.href,
      longUrl: `https://assets.studyring.org/assets/books/baws/EN/${volName}.pdf#page=${pageNum}`,
    });

    navigate(`/books/baws/EN/${volName}/pdf/${pageNum}`);
  };

  return (
    <>
      {data.map(function (searchRow, idx) {
        const pageContext = getPageContext(searchRow);
        var title = searchRow.data.container;
        if (pageContext.length) {
          var item = JSON.parse(pageContext[pageContext.length - 1]);
          title = item.label;
        }
        var result_url = `/books${searchRow.path.raw}${searchRow.container.raw}/pdf/${searchRow.actual_page.raw}`;
        if (userBookMarkDict.hasOwnProperty('baws.in' + result_url)) {
          title = title + '  ⭐';
        }
        return (
          <Row key={searchRow.id.raw} className="mt-3">
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>
                    <a
                      href={`/books${searchRow.path.raw}${searchRow.container.raw}/pdf/${searchRow.actual_page.raw}`}
                      onClick={(e) =>
                        setBookContextUrl(
                          e,
                          searchRow.container.raw,
                          searchRow.actual_page.raw
                        )
                      }
                    >
                      {`${title}`}
                    </a>
                  </Card.Title>
                  <div style={{ marginBottom: '1rem' }}>
                    <p>
                      {parse(searchRow.data.snippet.replace(/em>/g, 'strong>'))}
                    </p>
                    <a
                      href={`/books${searchRow.path.raw}${searchRow.container.raw}/pdf/${searchRow.actual_page.raw}`}
                      onClick={(e) =>
                        setBookContextUrl(
                          e,
                          searchRow.container.raw,
                          searchRow.actual_page.raw
                        )
                      }
                    >
                      {`${searchRow.container.raw} (Page No: ${searchRow.actual_page.raw})`}
                    </a>
                  </div>

                  <Breadcrumb>
                    {pageContext.map((context_str, i) => {
                      const { vol_name, page_start, label } =
                        JSON.parse(context_str);
                      return (
                        <Breadcrumb.Item
                          key={`${searchRow.id.raw}-${i}`}
                          href={`/books/baws/EN/${vol_name}/pdf/${page_start}`}
                          onClick={(e) =>
                            setBookContextUrl(e, vol_name, page_start)
                          }
                          style={{ fontSize: '0.75rem' }}
                        >
                          {label}
                        </Breadcrumb.Item>
                      );
                    })}
                  </Breadcrumb>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        );
      })}
    </>
  );
}
