import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import BahishkritBharatLogo from '../asset/BahishkrutBharatLogo.jpg';
import { bookHistory, key_bookUrl } from '../util/constants';

function BahishkritBharat(props) {
  const navigate = useNavigate();

  const load_blank = (event, url) => {
    event.preventDefault();

    // window.history.pushState("object or string", "Title", "/");
    // props.onChangeBookUrl(html_url);

    bookHistory.set(key_bookUrl, { shortUrl: event.target.href, longUrl: url });
    navigate(new URL(url).pathname);
  };

  return (
    <div style={{ marginBottom: '7%' }}>
      <div style={{ textAlign: 'center' }}>
        <p>
          <div
            style={{ height: 'auto', width: '300px', display: 'inline-block' }}
          >
            <img src={BahishkritBharatLogo} alt="logo" width={'100%'} />
          </div>
        </p>
        <br />
        <br />
        <p style={{ fontSize: '1.25em' }}>
          <strong>Bahishkrit Bharat Articles</strong>
        </p>
        <p style={{ fontSize: '1.25em' }}>
          <strong>Mahad Series</strong>
        </p>
        <div style={{ textAlign: 'justify' }}>
          Mahad Satyagrah was the first enormous mass protest of (to use the
          language of the time) the Untouchables under the leadership of Dr
          Babasaheb Ambedkar. It was not a crisis over availability of water. At
          Mahad, the Untouchables fought to establish their rights as human
          beings. Back in 1923, Bombay Legislative Council had adopted a
          resolution for opening Chawdar tank to the Untouchables. This
          resolution was introduced by S K Bole. Mahad municipality also
          reaffirmed it in 1926. However, the resolution remained a mere gesture
          as orthodox Hindus opposed the Untouchables from exercising their
          right to fetch water from the lake. On 23rd March 1927, a large number
          of Untouchables asserted their rights and drank water from Chawdar
          lake at Mahad. Dr Ambedkar wrote a series of articles in Bahishkrit
          Bharat on the Mahad Satyagraha, addressing Upper Caste Hindus, British
          Government, and the Untouchable Class, from 22nd April to 22nd May
          1927. We start with these important articles.
        </div>
        <div style={{ textAlign: 'justify' }}>
          Mooknayak (1920), Bahiskrit Bharat (1927-29), Janata (1930-56) and
          Prabuddha Bharat (1956); those four newspapers in Marathi were
          established by Dr Ambedkar. His journalistic writing played an
          important role in the anti-caste movement in the 20th Century. This is
          a collective attempt by our translation team to bring his work into
          English and make it open for non-Marathi as well as global readers.
        </div>
      </div>
      <Card className="mt-5" id="BBArticles">
        <Card.Body>
          <Card.Title></Card.Title>
          <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
          <Card.Text>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <p style={{ fontSize: '1.25em' }}>
                  <strong>Articles on Mahad Satyagraha</strong>
                </p>
                It is a matter of privilege and joy for us to start our journey
                with three important articles that Babasaheb published after the
                historic Mahad Satyagraha. Please proceed to these pages to read
                more on Mahad Satyagraha.
              </ListGroup.Item>
              <ListGroup.Item>
                <p>
                  <strong>
                    {' '}
                    <a
                      href="https://baws.in/books/baws/EN/bahishkrit-bharat/1927-04-22/mahad-responsibility-upper-caste-hindus.html"
                      onClick={(event) =>
                        load_blank(
                          event,
                          'https://baws.in/books/baws/EN/bahishkrit-bharat/1927-04-22/mahad-responsibility-upper-caste-hindus.html'
                        )
                      }
                    >
                      1) Religious war at Mahad and responsibility of Upper
                      Caste Hindus{' '}
                    </a>
                  </strong>
                </p>
                We opined that instead of calling the incident a riot, it will
                be more appropriate to call it a ‘Religious War’. Because, as
                the units of Hindu Society and the followers of the Hindu
                religion like any other Hindu Castes, whether we are eligible to
                have equal rights, whether our rights are equal or not, was the
                major question at the base of this water-issue. It is known by
                the entire world now that the Caste Hindus of Mahad gave a
                negative answer to this question.
              </ListGroup.Item>
              <ListGroup.Item>
                <p>
                  <strong>
                    {' '}
                    <a
                      href="https://baws.in/books/baws/EN/bahishkrit-bharat/1927-05-06/mahad-responsibility-british-gov.html"
                      onClick={(event) =>
                        load_blank(
                          event,
                          'https://baws.in/books/baws/EN/bahishkrit-bharat/1927-05-06/mahad-responsibility-british-gov.html'
                        )
                      }
                    >
                      2) Religious war at Mahad and responsibility of the
                      British Government{' '}
                    </a>
                  </strong>
                </p>
                It is shameful for a powerful government like the British
                Government if the Untouchables have to wait for the awareness
                among the caste-Hindus to develop. It's been six years since the
                resolution was passed, but the number of local institutions that
                have implemented it is almost negligible.
              </ListGroup.Item>
              <ListGroup.Item>
                <p>
                  <strong>
                    {' '}
                    <a
                      href="https://baws.in/books/baws/EN/bahishkrit-bharat/1927-05-20/mahad-responsibility-untouchable-class.html"
                      onClick={(event) =>
                        load_blank(
                          event,
                          'https://baws.in/books/baws/EN/bahishkrit-bharat/1927-05-20/mahad-responsibility-untouchable-class.html'
                        )
                      }
                    >
                      3) Religious war at Mahad and responsibility of the
                      Untouchable Class{' '}
                    </a>
                  </strong>
                </p>
                Those people from the untouchable brethren who have realized who
                they are and what is their real-self, should be prepared to
                destroy the blot of untouchability on their people by
                participating in the religious war that started just now
              </ListGroup.Item>
            </ListGroup>
          </Card.Text>
        </Card.Body>
      </Card>
      <div style={{ textAlign: 'justify' }}>
        <p> </p>
        <strong> About Editor: </strong>
        <p>
          Sagar Kamble is a Visiting Doctoral Researcher at Freie Universitat
          Berlin. He is doing PhD at the University of Mumbai. He worked as a
          lecturer in political science at Sir Parashurambhau College Pune. He
          is currently involved in this translation project as Editor and Team
          Lead. He can be contacted at
          <a href="mailto:sagar.kamble@civics.mu.ac.in">
            {' '}
            sagar.kamble@civics.mu.ac.in{' '}
          </a>{' '}
        </p>
        <p> Reviewers: Trisheela Gaikwad, Manish Gajbhiye, Prakash Ransing </p>
        <p>
          <strong>A Note from translation Team:</strong>
        </p>
        <p>
          {' '}
          In order to preserve the spirit of Babasaheb Ambedkar's work, our team
          of volunteers put a lot of effort into this translation. Although we
          have tried to be faithful to the original Marathi, we have
          occasionally changed the phrase from passive to active to make things
          more clear, particularly in lengthy parts. Please report any problems
          you encounter; we really appreciate your input. Our combined efforts
          aim to break down linguistic barriers and make Ambedkar's views
          understandable to a worldwide audience.{' '}
        </p>
        <p> Thanks </p>
        <p> Translation Team </p>
        <p> </p>
        <p> </p>
      </div>
    </div>
  );
}

export default BahishkritBharat;
