import React, { createElement, Fragment, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import Fuse from 'fuse.js';
import { autocomplete } from '@algolia/autocomplete-js';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import '@algolia/autocomplete-theme-classic';
import start_data from '../asset/start.json';

function book2pdf(book_page, vol_name) {
  let ret_page = book_page + start_data[vol_name];
  switch (vol_name) {
    case 'Volume_10':
      if (book_page > 398) return ret_page + 4;
      break;
    case 'Volume_15':
      if (book_page > 704) return ret_page + 2;
      break;
    case 'Volume_17_02':
      if (book_page > 300) return ret_page + 2;
      break;
    default:
      return ret_page;
  }
  return ret_page;
}

let fuse = null;
let fusePromise = null;

async function getFuse() {
  if (fuse) return fuse;
  if (fusePromise) return await fusePromise;

  fusePromise = import('../asset/suggestion.json')
    .then((module) => {
      const suggestions = module.default;
      const updatedSuggestions = suggestions.map((item) => ({
        ...item,
        label: `"${item.label.replace(/^[\d+.\s\-()]+/, '')}"`,
        page_start: book2pdf(item.page_start, item.vol_name),
      }));
      fuse = new Fuse(updatedSuggestions, {
        findAllMatches: true,
        minMatchCharLength: 3,
        threshold: 0.3,
        ignoreLocation: true,
        keys: ['label'],
      });
      return fuse;
    })
    .catch((err) => {
      // Handle failure
      fusePromise = null;
      throw err;
    });

  return await fusePromise;
}

const getUrl = (item) =>
  `${window.location.protocol}//${window.location.hostname}/books/baws/EN/${item.vol_name}/pdf/${item.page_start}`;

const AutoComplete = (props) => {
  const containerRef = useRef(null);
  const panelRootRef = useRef(null);
  const rootRef = useRef(null);
  const searchRef = useRef(null);

  const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
    key: 'RECENT_SEARCH',
    limit: 6,
    transformSource({ source }) {
      return {
        ...source,
        onSelect(params) {
          if (params.item.label) {
            params.setIsOpen(false);
            params.setQuery(params.item.label);
            props.onSelect(params.item.label);
          }
        },
      };
    },
  });

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const initializeSearch = async () => {
      try {
        const fuseInstance = await getFuse();
        searchRef.current = autocomplete({
          container: containerRef.current,
          renderer: { createElement, Fragment, render: () => {} },
          render({ children }, root) {
            if (!panelRootRef.current || rootRef.current !== root) {
              rootRef.current = root;

              panelRootRef.current?.unmount();
              panelRootRef.current = createRoot(root);
            }

            panelRootRef.current.render(children);
          },
          plugins: [recentSearchesPlugin],
          getSources() {
            return [
              {
                sourceId: 'label',
                getItems({ query }) {
                  return fuseInstance.search(query);
                },
                getItemInputValue({ item, state }) {
                  return item.item.label;
                },
                onSelect(params) {
                  if (params.item.item.label) {
                    params.setIsOpen(false);
                    props.onSelect(params.item.item.label);
                    params.setQuery(params.item.item.label);
                  }
                },
                templates: {
                  item({ item, components }) {
                    return (
                      <>
                        <h6 style={{ display: 'flex', gap: '0.5em' }}>
                          {item.item.label}
                          <a
                            href={getUrl(item.item)}
                            onClick={(e) =>
                              props.onSetBookUrl(
                                e,
                                item.item.vol_name,
                                item.item.page_start
                              )
                            }
                          >
                            Open Direct
                          </a>
                        </h6>
                        <p style={{ fontSize: '0.75em' }}>
                          {item.item.vol_name}
                        </p>
                      </>
                    );
                  },
                },
              },
            ];
          },
          onSubmit(params) {
            if (params.state.query) {
              props.onSelect(params.state.query);
            }
          },
          placeholder: 'Type here to Search in BAWS',
          detachedMediaQuery: 'none',
          initialState: {
            query: props.defaultValue,
          },
        });
      } catch (err) {
        // Handle error
        console.error('Failed to initialize search:', err);
      }
    };

    initializeSearch();

    return () => {
      if (searchRef.current) {
        searchRef.current.destroy();
      }
    };
  }, [props, recentSearchesPlugin]);

  return <div ref={containerRef} />;
};

export default AutoComplete;
