import React from 'react';
import BahishkritBharatLogo from '../asset/mooknayak.jpeg';

import { Link } from 'react-router-dom';
import { Card, ListGroup } from 'react-bootstrap';
import { swarajCritiqueDB } from './SwarajCritiqueJS';

function SwarajCritiqueArticles() {
  return (
    <div style={{ marginBottom: '7%' }}>
      <div style={{ textAlign: 'center' }}>
        <p>
          <div
            style={{ height: 'auto', width: '300px', display: 'inline-block' }}
          >
            <img src={BahishkritBharatLogo} alt="logo" width={'100%'} />
          </div>
        </p>
        <br />
        <br />
        <p style={{ fontSize: '1.25em' }}>
          <strong>Critique of Swaraj</strong>
        </p>
        <p style={{ fontSize: '1.25em' }}>
          <strong>Critique of Swaraj and other articles</strong>
        </p>
        <div style={{ textAlign: 'justify' }}>
          <p>
            This translated article series (Critique of Swaraj) delves into how
            Dr B R Ambedkar criticised the mainstream Indian nationalist
            narrative of self-rule. His vision didn't just offer an alternative
            to the prevailing concept of Swaraj; it went beyond, providing a
            universal framework. He raised the question, Swaraj, for whom and
            for what? While the Congress party clamoured for Swaraj, Dr Ambedkar
            reminded them how untouchables could assert their right to
            self-determination against the oppression of upper-caste rule. In
            "Annihilation of Caste," he underscored this principle by affirming
            to upper-caste Congress leaders that just as one country isn't fit
            to govern another, one class or community shouldn't rule another.
          </p>
        </div>
        <div style={{ textAlign: 'justify' }}>
          Through selected articles from his inaugural newspaper 'Mooknayak,' we
          highlight how Dr Ambedkar shed light on the looming threat of
          Brahminical supremacy in future independent India. He analysed in
          detail the newly forming representative democracy. He cautioned
          against the mere replacement of British colonial rule with upper-caste
          dominance. In one of the articles in Mooknayak, he wrote that the real
          meaning of Swaraj is the freedom given to create social conditions one
          requires to achieve self-fulfilment.
        </div>
      </div>
      <Card className="mt-5" id="BBArticles">
        <Card.Body>
          <Card.Title></Card.Title>
          <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
          <Card.Text>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <p style={{ fontSize: '1.25em' }}>
                  <strong>Swaraj critique and other articles</strong>
                </p>
                Please proceed to these pages to read more on Swaraj critique
                and other articles.
              </ListGroup.Item>
              {swarajCritiqueDB.map((article) => {
                return (
                  <ListGroup.Item key={article.id}>
                    <p>
                      <strong>
                        {' '}
                        <Link
                          to={`/books/baws/EN/swaraj-critique/${article.pbDate}/${article.mdLink}`}
                        >
                          {`${article.id}) ${article.title}`}{' '}
                        </Link>
                      </strong>
                    </p>
                    {article.description}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Card.Text>
        </Card.Body>
      </Card>
      <div style={{ textAlign: 'justify' }}>
        <p> </p>
        <strong> About Editor: </strong>
        <p>
          Sagar Kamble is a Visiting Doctoral Researcher at Freie Universitat
          Berlin. He is doing PhD at the University of Mumbai. He worked as a
          lecturer in political science at Sir Parashurambhau College Pune. He
          is currently involved in this translation project as Editor and Team
          Lead. He can be contacted at
          <a href="mailto:sagar.kamble@civics.mu.ac.in">
            {' '}
            sagar.kamble@civics.mu.ac.in{' '}
          </a>{' '}
        </p>
        <p>
          {' '}
          Reviewers: Prarthana Tambe, Manish Gajbhiye, Trisheela Gaikwad, Sagar
          Kamble, Shruti Ranawade, Hritika Mohite, Prashant Nema, Prashik
          Sarkate.
        </p>
        <p>
          {' '}
          Translators: Meenakshi Patil, Chetana Kamble, Aditi Morale, Nitin
          Kumar, Milind Thokal, Hritika Mohite, Prashik Sarkate, Shantinee
          Sutar, Prarthana Tambe{' '}
        </p>
        <p>
          <strong>A Note from translation Team:</strong>
        </p>
        <p>
          {' '}
          In order to preserve the spirit of Babasaheb Ambedkar's work, our team
          of volunteers put a lot of effort into this translation. Although we
          have tried to be faithful to the original Marathi, we have
          occasionally changed the phrase from passive to active to make things
          more clear, particularly in lengthy parts. Please report any problems
          you encounter; we really appreciate your input. Our combined efforts
          aim to break down linguistic barriers and make Ambedkar's views
          understandable to a worldwide audience.{' '}
        </p>
        <p> Thanks </p>
        <p> Translation Team </p>
        <p> </p>
        <p> </p>
      </div>
    </div>
  );
}

export default SwarajCritiqueArticles;
